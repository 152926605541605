<template>
    <div>
        <h1>System Options</h1>
        <ul class="nav nav-pills">
            <li v-for="nav in navList" :class="classList(nav)"  @click="view = nav.view" :key="nav.view"><a>{{ nav.label }}</a></li>
        </ul>
        <LaborRate v-if="view === 'laborRate'" />
        <SalesTerritories v-if="view === 'salesAssignments'" />
    </div>
</template>

<script>

import LaborRate from "@/components/tools/Config/LaborRate.vue";
import SalesTerritories from "@/components/tools/Config/SalesTerritories.vue";

export default {
    name: 'laborRate',
    components: {
        SalesTerritories,
        LaborRate,

    },
    data() {
        return {
            view: 'laborRate',
            navList: [
                {
                    view: 'laborRate',
                    label: 'Labor Rate'
                },
                {
                    view: 'salesAssignments',
                    label: 'Sales Territory Assignments',
                },
                {
                    view: 'surchargeConfig',
                    label: 'Surcharge Manager',
                    disabled: true
                }
            ]
        }
    },
    methods: {
        classList: function(nav) {
            let classList = [];
            if (nav.disabled) {
                classList.push('disabled');
            }
            if (this.view === nav.view) {
                classList.push('active')
            }
            return classList.join(' ')
        }
    }
}

</script>

<style scoped>
    .nav > li {
        cursor: pointer !important;
    }
</style>
