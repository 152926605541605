<template>
    <div>
        <h3>Labor Rate Configuration</h3>
        <table class="table">
            <thead>
            <tr>
                <th>CDI Location</th>
                <th class="number">Labor Rate Goal</th>
                <th class="number">Labor Recognition Modifier</th>
                <th class="number">Net Sales Modifier</th>
            </tr>
            </thead>
            <tbody>
            <tr >
                <td>CDI</td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Goal:</div>
                        <input v-model="data[0]['laborRateGoal']" class="form-control number" type="text">
                    </div>
                </td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Payroll X </div>
                        <input v-model="data[0]['laborRecognitionModifier']" class="form-control number" type="text">
                    </div>
                </td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Net Sales X </div>
                        <input v-model="data[0]['netSalesModifier']" class="form-control number" type="text">
                    </div>
                </td>
            </tr>
            <tr v-for="[cdiLocationID, cdilocation] in cache.cdiLocation.CDI_LOCATIONS" :key="cdiLocationID">
                <td>{{ cdilocation }}</td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Goal:</div>
                        <input v-model="data[cdiLocationID]['laborRateGoal']" class="form-control number" type="text">
                    </div>
                </td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Payroll X </div>
                        <input v-model="data[cdiLocationID]['laborRecognitionModifier']" class="form-control number" type="text">
                    </div>
                </td>
                <td>
                    <div class="input-group">
                        <div class="input-group-addon">Net Sales X </div>
                        <input v-model="data[cdiLocationID]['netSalesModifier']" class="form-control number" type="text">
                    </div>
                </td>
            </tr>
            </tbody>
        </table>

        <button @click="saveLaborRateConfig" class="btn btn-primary" type="button">Save</button>
    </div>
</template>


<script>

import cdiLocationCache from "@/cache/cdiLocation.cache";
import { store } from "@/store/Tool.store";

export default {
    name: 'LaborRate',
    data() {
        return {
            cache: {
                cdiLocation: cdiLocationCache
            },
            data: []
        }
    },
    created() {
        this.data = [];
        this.data.push({
            cdiLocationID: 0,
            laborRateModifier: 0.00,
            netSalesModifier: 0.00
        });
        for (let i = 0; i < this.cache.cdiLocation.CDI_LOCATIONS.length; i++) {
            this.data.push({
                cdiLocationID: this.cache.cdiLocation.CDI_LOCATIONS[i][0],
                laborRateModifier: 0.00,
                netSalesModifier: 0.00,
                laborRateGoal: 0.00
            })
        }
    },
    methods: {
        loadLaborRateConfig: function() {
            store.api('/config/get_labor_rate_config', {}).then((res) => this.data = res.data);
        },
        saveLaborRateConfig: function() {
            let options = {};
            for (let i = 0; i < this.data.length; i++) {
                options['params[' + this.data[i]['labor_rate_modifier_id'] + ']'] = {
                    labor_recognition_modifier: this.data[i].laborRecognitionModifier,
                    net_sales_modifier: this.data[i].netSalesModifier,
                    labor_rate_goal: this.data[i].laborRateGoal
                }
            }
            store.api('/config/save_labor_rate_config', options).then((res) => this.data = res.data);
        }
    },
    mounted() {
        this.loadLaborRateConfig();
    }
}

</script>