<template>
  <div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <label>Territory</label>
          <select class="form-control" ref="territory" v-model="formData.territoryId">
            <option value="0">None</option>
            <option v-for="[territoryId, territory] in territoryOpts" :key="territoryId" :value="territoryId">{{ territory }}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label>State</label>
          <select class="form-control" v-model="formData.stateId" >
            <option value="0">None</option>
            <option v-for="[stateId, state] in cache.state" :key="stateId" :value="stateId">{{ state }}</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="form-group">
          <label>Zip Prefix</label>
          <input type="text" class="form-control" v-model="formData.zipCode" />
        </div>
      </div>
      <div class="col-sm-12">
        <button @click="search" class="btn btn-primary">Search</button>
        <button @click="openMassEdit" class="btn btn-default">Mass Edit</button>
        <button class="btn btn-warning" @click="syncModal = !syncModal"><span class="glyphicon glyphicon-warning-sign"></span> Sync to Users</button>
      </div>
      <div class="col-sm-12">
        <table class="table table-responsive">
          <thead>
          <tr>
            <th>Territory</th>
            <th>State</th>
            <th>Zip</th>
            <th>SSR</th>
            <th>RSR</th>
            <th>Measure Tech</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="result in results" :key="result.zip_code">
            <td>{{ result.territory_name }} <button @click="openSingleEdit(result.zip_code_id, 'territory_id', result.territory_id)" class="btn btn-link"><span class="glyphicon glyphicon-pencil "></span></button></td>
            <td>{{ result.state_name }}</td>
            <td>{{ result.zip_code }}</td>
            <td>{{ result.ssr_name }} <button @click="openSingleEdit(result.zip_code_id, 'sales_support_rep_id', result.ssr_user_id)" class="btn btn-link"><span class="glyphicon glyphicon-pencil "></span></button></td>
            <td>{{ result.rsr_name }} <button @click="openSingleEdit(result.zip_code_id, 'regional_sales_rep_id', result.rsr_user_id)" class="btn btn-link"><span class="glyphicon glyphicon-pencil "></span></button></td>
            <td>{{ result.mt_name }} <button @click="openSingleEdit(result.zip_code_id, 'measure_tech_id', result.measure_tech_user_id)" class="btn btn-link"><span class="glyphicon glyphicon-pencil "></span></button></td>
          </tr>
          </tbody>
        </table>
      </div>

      <Modal :heading="singleEditFieldLabel" v-if='singleEditModal' @close="closeSingleEditModal">
        <template v-slot:body>
          <select v-if="focusField === 'territory_id'" class="form-control" v-model="focusValue">
            <option value="0">None</option>
            <option v-for="[territoryId, territory] in territoryOpts" :key="territoryId" :value="territoryId">{{ territory }}</option>
          </select>
          <select v-if="focusField === 'measure_tech_id'" class="form-control" v-model="focusValue">
            <option value="0">None</option>
            <option v-for="[mtId, measureTech] in measuretechOpts" :key="mtId" :value="mtId">{{ measureTech }}</option>
          </select>
          <select v-else class="form-control" v-model="focusValue">
            <option value="0">None</option>
            <option v-for="[salesId, salesPerson] in salespersonOpts" :key="salesId" :value="salesId">{{ salesPerson }}</option>
          </select>
        </template>
        <template v-slot:footer>
          <button class='btn btn-primary' @click='saveSingle'>Update</button>
          <button class='btn btn-default' @click='closeSingleEditModal'>Cancel</button>
        </template>
      </Modal>

            <Modal heading="Mass Sales Team Editor" v-if='massEditModal' @close="closeMassEditModal">
                <template v-slot:body>
                    <div class="form-group">
                        <label>Regional Sales Rep</label>
                        <select class="form-control" v-model="massRsr">
                            <option value="0">None</option>
                            <option v-for="[salesId, salesPerson] in salespersonOpts" :key="salesId" :value="salesId">{{ salesPerson }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label>Sales Support Rep</label>
                        <select class="form-control" v-model="massSsr">
                            <option value="0">None</option>
                            <option v-for="[salesId, salesPerson] in salespersonOpts" :key="salesId" :value="salesId">{{ salesPerson }}</option>
                        </select>
                    </div>
                </template>
                <template v-slot:footer>
                    <button class='btn btn-primary' @click='saveMass'>Update</button>
                    <button class='btn btn-default' @click='closeMassEditModal'>Cancel</button>
                </template>
            </Modal>
            <ConfirmBox
                    v-if="syncModal"
                    :heading="'Execute sales territory sync?'"
                    :body="'This will run a full sweep on active customers and update territories to match the rules that are outlined in this tool'"
                    @close="sync"
            />
        </div>
    </div>
</template>

<script>
import stateCache from "@/cache/state.cache";
import {store} from "@/store/Tool.store";
import Modal from "@/components/utils/Modal.vue";
import ConfirmBox from "@/components/utils/ConfirmBox.vue";

export default {
  components: {ConfirmBox, Modal},
  created() {
    this.getTerritories();
    this.getSalesStaff();
    this.getmeasureTechs();
  },
  data() {
    return {
      territoryOpts: [],
      salespersonOpts: [],
      formData: {
        territoryId: 0,
        stateId: 0,
        zipCode: ''
      },
      cache: {
        state: stateCache.STATES,
        singleEditFieldLabels: {
          territory_id: 'Territory',
          regional_sales_rep_id: 'Regional Sales Rep',
          sales_support_rep_id: 'Sales Support Rep',
          measure_tech_id: 'Measure Tech'
        }
      },
      validation: {
        singleFieldWhitelist: ['territory_id', 'regional_sales_rep_id', 'sales_support_rep_id', 'measure_tech_id']
      },
      results: [],
      singleEditFieldLabel: '',
      singleEditModal: false,
      massEditModal: false,
      focusId: 0,
      focusField: '',
      focusValue: 0,
      massSsr: 0,
      massRsr: 0,
      reverseSyncModal: false,
      syncModal: false
    }
  },
  methods: {
    getTerritories: function() {
      let scope = this;
      store.api('/menu_builder/sales_territories', {}).then(res => {
        scope.territoryOpts = res;
      });
    },
    getSalesStaff: function() {
      let scope = this;
      store.api('/menu_builder/sales_staff', {}).then(res => {
        scope.salespersonOpts = res;
      });
    },
    getmeasureTechs: function() {
      let scope = this;
      store.api('/menu_builder/measure_techs', {}).then(res => {
        scope.measuretechOpts = res;
      });
    },
    search: function() {
      let scope = this;
      store.api('/config/get_territory_data', {
        params: scope.formData
      }).then((res) => {
        if (res.opt == 'error') {
          scope.$appStore.errorBox(res.msg);
        } else {
          scope.results = res.data;
        }
      });
    },
    openMassEdit: function() {
      this.massEditModal = true;
    },
    openSingleEdit: function(id, field, value = 0) {
      this.singleEditModal = true;
      this.singleEditFieldLabel = this.cache.singleEditFieldLabels[field];
      this.focusId = id;
      this.focusField = field;
      this.focusValue = value;
    },
    closeSingleEditModal: function() {
      this.singleEditModal = false;
      this.singleEditFieldLabel = '';
      this.focusId = 0;
      this.focusField = '';
      this.focusValue = 0;
    },
    closeMassEditModal: function() {
      this.massEditModal = false;
    },
    saveSingle: function() {
      if (!this.validation.singleFieldWhitelist.includes(this.focusField)) {
        this.$appStore.errorBox('Failed to properly build your request. If this issue persists, contact dev team for assistance. [Error Code 1]');
        return;
      }

            if (!this.focusValue) {
                this.$appStore.errorBox('Failed to properly build your request. If this issue persists, contact dev team for assistance. [Error Code 2]');
                return;
            }

            let requestParams = {
                params: {
                    key: this.focusField,
                    value: this.focusValue,
                    zip_code_id: this.focusId
                }
            }

            let scope = this;
            store.api('/config/save_single_sales_territory_value', requestParams).then((res) => {
                if (res.opt === 'error') {
                    scope.$appStore.errorBox(res.msg);
                } else if (res.opt === 'get') {
                    this.search();
                }
            });

            this.closeSingleEditModal();
        },
        saveMass: function() {
            let params = {
                params: {
                    filter: this.formData,
                    ssr: this.massSsr,
                    rsr: this.massRsr
                }
            }

            store.api('/config/save_mass_territory_data', params).then((res) => {
                if (res.opt === 'get') {
                    this.search();
                } else if (res.opt === 'error') {
                    this.$appStore.errorBox('res.msg');
                }
            });

            this.closeMassEditModal()
        },
        sync: function(confirmed) {
            if (confirmed) {
                store.api('/config/sync_sales_territory_zips_to_users', {});
            }
            this.syncModal = false;
        }
    }
}
</script>